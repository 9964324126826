html,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

/* Start Login Section */

.Login_mainContainer {
  text-align: center;
}
.Login_Wingsure_Logo {
  margin: 2% 0px 2% 0px;
  height: 40px;
  width: 160px;
  border: 0px solid #000;
}
.Login_mainContainer h1 {
  font-size: 38px;
  font-style: normal;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  margin: 0px 0px 1% 0px;
  color: #393939;
}
.Login_mainContainer h5 {
  font-size: 16px;
  color: #6c6c6c;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
}
.Login_bodyPic {
  height: 8%;
}
.Login_mainContainer h6 {
  margin: 0.5% 0px 1% 0px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #393939;
}
/* .longin_imageContainer {border: 0px solid #D9D9D9; width: 350px;  } */
.longin_imageContainer_active {
  width: 300px;
  height: 40px;
  padding: 0px 1% 0px 5px;
  border: 1px solid #2149e9;
  text-align: left;
  display: inline-flex;
  flex-direction: row;
}
.longin_imageContainer {
  width: 300px;
  height: 40px;
  padding: 0px 10px 0px 5px;
  border: 1px solid #d9d9d9;
  text-align: left;
  display: inline-flex;
  flex-direction: row;
  background-color: #f7f7f7;
  box-sizing: border-box;
}
.CountryCodeInput {
  height: 100%;
  width: 15%;
  margin: 0px 0px 0px 0px;
  border: 0px solid #b6bebe;
  color: #363636;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
}
.mobileNumberInput {
  width: 80%;
  height: 100%;
  border: 0px solid #7f8c8d;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #363636;
  background-color: transparent;
}
.mobileNumberInput_active {
  width: 80%;
  height: 100%;
  border: 0px solid #7f8c8d;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
  background-color: transparent;
}
.tickImage_container {
  width: 5%;
  height: 10px;
}
.tickImage_container img {
  width: 95%;
  height: 100%;
  margin: 10px 0px 0px 3px;
  border: 0px solid #7f8c8d;
}
.LoginButton_active {
  margin: 0% 0px 1% 0px;
  width: 150px;
  height: 35px;
  border: 0px solid #7f8c8d;
  background-color: #2149e9;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.LoginButton {
  margin: 0% 0px 1% 0px;
  width: 150px;
  height: 35px;
  border: 0px solid #7f8c8d;
  background-color: #efefef;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
/* End Login Section */

/* Start OTP Verification Section */
.OTPVerification_mainContainer {
  text-align: center;
}
.OTPVerification_backButton {
  text-align: left;
  margin: 1% 0px 0px 5%;
  font-size: 18px;
  cursor: pointer;
}
.OTPVerificatio_Wingsure_Logo {
  margin: 0px 0px 2% 0px;
  height: 45px;
  width: 140px;
  border: 0px solid #000;
}
.OTPVerification_mainContainer h1 {
  font-size: 35px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-style: normal;
  margin: 0px 0px 10px 0px;
  color: #393939;
}
.OTPVerification_mainContainer h5 {
  font-size: 16px;
  color: #6c6c6c;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 13px;
}
.OTPVerification_mainContainer img {
  height: 8%;
}
.OTPVerification_mainContainer h6 {
  margin: 0% 0px 10px 0px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #393939;
}
.otp_input {
  width: 46px;
  height: 40px;
  border: 1px solid #d9d9d9;
  margin: 0px 5px 5px 5px;
  padding: 0px 0px 0px 17px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background-color: #f7f7f7;
}
.otp_input_active {
  width: 46px;
  height: 40px;
  border: 1px solid #2149e9;
  margin: 0px 5px 5px 5px;
  padding: 0px 0px 0px 17px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.OTPVerification_imageContainer img {
  width: 18px;
  height: 18px;
  margin: 0px 0px 0px 2%;
}
.reciveOTP {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #aaaaaa;
  margin: 0% 0px 0px 0px;
}
.reciveOTP span {
  color: #2149e9;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
  font-size: 12px;
}
.OTPVerificationButton_active {
  margin: 1% 0px 2% 0px;
  width: 150px;
  height: 35px;
  border: 0px solid #537cd9;
  background-color: #2149e9;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.OTPVerificationButton {
  margin: 1% 0px 2% 0px;
  width: 150px;
  height: 35px;
  border: 0px solid #d9d9d9;
  background-color: #efefef;
  color: #c5c5c5;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
/* End OTP Verification Section */

/* Start Select Your Role page Section */
.SelectRole_mainContainer {
  text-align: center;
}
.SelectRole_mainContainer h3 {
  margin: 40px 0px 50px 0px;
  font-weight: 200;
}
.SelectRole_mainContainer h1 {
  font-size: 35px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  margin: 0px 0px 15px 0px;
}
/* .SelectRole_mainContainer h5 {font-size: 17px; color: #7F8C8D; font-family: , sans-serif; } */
.SelectRole_mainContainer button {
  margin: 40px 10px 50px 10px;
  width: 200px;
  height: 200px;
  border: 1px solid #000;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
}
.SelectRole_mainContainer button:hover {
  margin: 40px 10px 50px 10px;
  width: 200px;
  height: 200px;
  border: 1px solid #2149e9;
  background-color: #eef1f2;
  border-radius: 5px;
}
/* End Select Your Role page Section */

/* Start Header Section */
.headerContainer {
  height: 110px;
  padding: 30px 4% 0px 4%;
  border-bottom: 0px solid #7f8c8d;
  width: 100%;
  position: fixed;
  top: 0px;
  background-color: #ffff;
  z-index: 100;
}
.headerLogo {
  height: 40px;
  width: 160px;
}
.headerInput_container {
  background-color: #f0f0f0;
  border: 0px solid #7f8c8d;
  width: 100%;
  height: 40px;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: row;
}
.headerInput {
  border: 0px solid #7f8c8d;
  width: 85%;
  background-color: #f0f0f0;
  padding: 0px 10px 0px 10px;
  height: 100%;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #a5a5a5;
}
.searchIcon {
  width: 15%;
  height: 100%;
  border: 0px solid #7f8c8d;
  padding: 10px 1.5% 0px 0px;
  text-align: right;
  cursor: pointer;
}
.bell_Icon {
  padding: 5px 0px 0px 0px;
  text-align: center;
}
.Profile_container {
  border: 0px solid #7f8c8d;
  padding: 0px 0px 0px 0px;
  display: inline-flex;
  flex-direction: row;
  /* width: 100%; */
}
/* .profileIcon {width: 42px; height: 42px; border-radius: 25px; border: 0px solid #7F8C8D; resize: inherit;} */
.profileName {
  padding: 7px 0px 0px 0px;
  border: 0px solid #7f8c8d;
  width: 80%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #393939;
  text-align: right;
}
.arrowBottom {
  padding: 5px 0px 0px 0px;
  border: 0px solid #7f8c8d;
  width: 20%;
  text-align: right;
}
.searchListSurveyContainer {
  position: absolute;
  border: 1px solid rgb(136, 131, 131);
  width: 46%;
  height: 200px;
  background-color: #ffff;
  left: 21%;
  top: 110px;
  overflow-y: scroll;
  border-radius: 10px;
}
.searchListSurvey_rowContainer {
  padding: 5px 0px 5px 10px;
  margin: 1px 0px 1px 0px;
  background-color: #7f8c8d;
  color: #ffff;
  cursor: pointer;
  border-radius: 8px;
}
/* .searchListSurvey_text {} */
.signinView_container {
  position: absolute;
  top: 80px;
  background-color: rgb(152, 159, 160);
  right: 70px;
  z-index: 33;
  height: 100px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px #ccc;
}
.signinView_innerContainer {
  text-align: center;
}
.signinView_innerContainer h6 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #ffff;
  letter-spacing: 1.5px;
}
.signinView_innerContainer button {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin: 15px 0px 0px 0px;
  width: 100%;
  border: 0px solid #000;
  /* box-shadow: 0px 1px 5px #ccc; */
}
/* End Header Section  */

/* Start Home Page */
.surveyFormList_mainContainer {
  margin: 110px 0px 0px 0px;
  background-color: #f7f9fe;
  padding: 0px 0px 20px 0px;
}
/* Start Pagination Section */
.paginationContainer {
  min-height: 90px;
  padding: 40px 15px 0px 4%;
  margin: 0px 0px 10px 0px;
  border: 0px solid #000;
}
.leftPagination_container {
  border: 0px solid #000;
  padding: 5px 0px 0px 0px;
}
.rightPagination_container {
  text-align: right;
  border: 0px solid #7f8c8d;
}
.claimSurvey_text {
  font-size: 24px;
  font-weight: bold;
  color: #393939;
  border: 0px solid #7f8c8d;
  font-family: "Playfair Display", serif;
  font-style: normal;
}
.pageCount_textContainer {
  background-color: #7f8c8d;
  height: 20px;
  width: 20px;
  padding: 4px 0px 0px 0px;
}
.pageCount_text {
  color: #ffff;
  text-align: center;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}
.pageCount_dropdown {
  text-align: right;
  border: 0px solid #000;
}
.dropdownpageCount {
  max-width: 93px;
  min-width: 80px;
}
.claimSurvey_visibleClaimCount {
  font-size: 14px;
  width: 93px;
  color: #363636;
  border: 0.5px solid #d9d9d9;
  margin: 7px 0px 0px 0px;
  padding: 2px 0px 2px 2px;
  background-color: #ffff;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 3px;
}
.pagination_text {
  font-size: 14px;
  margin: 10px 0px 0px 0px;
  border: 0px solid #000;
  width: 100%;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
}
/* End Pagination Section */

/* Home Filter Section  */
.surveyFormFilter_container {
  max-height: 350px;
  overflow-y: auto;
  position: fixed;
  top: 200px;
  left: 50%;
  width: 300px;
  border: 1px solid rgb(230, 224, 224);
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #7f8c8d;
  padding: 10px 0 15px 0;
  /* height: 350px; overflow-y: scroll; */
}
.surveyFormFilter_container span {
  /* font-size: 11px; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 10px 0px 10px 10px;
  color: #878787;
}
.rowsurveyFormFilter_mainItemCheckBox {
  font-size: 12px;
  font-weight: 500;
  border: 0px solid #000;
  padding: 2px 0px 2px 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #363636;
  line-height: 22px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #ececec;
}
.rowsurveyFormFilter_mainItemCheckBox input {
  margin: 0px 7px 0px 0px;
}
.rowsurveyFormFilter_innerCheckBoxContainer {
  padding: 8px 0px 8px 20px;
  max-height: 130px;
  overflow-y: scroll;
}
.rowsurveyFormFilter_buttonContainer {
  width: 100%;
  border: 0px solid #000;
  margin-top: 8px;
}
.rowsurveyFormFilter_buttonCancel {
  border: 1px solid #363636;
  width: 45%;
  margin: 0px 10px 0px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #363636;
  line-height: 20px;
}
.rowsurveyFormFilter_buttonApply {
  width: 45%;
  background-color: #363636;
  color: #ffffff;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #363636;
  border-radius: 5px;
  line-height: 20px;
}
/* Home Intimated Section  */
.IntimatedClaim_container {
  padding: 0px 0px 0px 0px;
  border-bottom: 0.5px solid #e2e2e2;
  height: 40px;
  margin: 0px 4% 30px 4%;
}
.IntimatedClaim_innercontainer {
  display: inline-flex;
  flex-direction: row;
  height: 100%;
}
.IntimatedClaim_active {
  margin: 0px 20px 0px 0px;
  border-bottom: 3px solid #3a6bda;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #363636;
}
.IntimatedClaim_nonActive {
  border-bottom: 0px solid #000;
  height: 100%;
  cursor: pointer;
  margin: 0px 20px 0px 0px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #9c9c9c;
}

/* Start Claim Survey Listing Section */
.Listing_mainContainer {
  margin: 20px 4% 50px 4%;
}
.SurveyFormList_container {
  border: 0px solid #7f8c8d;
  padding: 1% 2% 1% 2%;
  border-radius: 10px;
  background-color: #ffff;
}
.SurveyFormList_tableHeader {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #393939;
}
.SurveyFormList_tableRowBody td {
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #363636;
}
.SurveyFormList_tableRowCheckBox {
  cursor: pointer;
  margin: 7px 0px 5px 0px;
}
.SurveyFormList_StatusDate_container {
  font-size: 11px;
}

.SurveyFormList_StatusDate_container div {
  font-size: 12px;
}

/* .SurveyFormList_StatusDate_container {} */
.SurveyFormList_StatusCircleText_container {
  font-size: 11px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  display: inline-flex;
  flex-direction: row;
}
.SurveyFormList_StatusCircle {
  height: 9px;
  width: 9px;
  border-radius: 10px;
  margin: 4px 3px 0px 0px;
}
.SurveyFormList_StatusDate_container b {
  font-size: 10px;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #363636;
}

.headTable {
  width: 100%;
  min-width: 800px;
}
.headTable th {
  padding: 20px 10px 20px 0px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.headTable th:first-child {
  padding-left: 10px;
}
.headTable td {
  padding: 7px 10px 7px 0px;
  text-align: left;
}

.headTable tr a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: table-cell;
  padding-top: 10px;
}

.headTable tr:hover td {
  background: #ccc;
  cursor: pointer;
}
.headTable tr:hover a {
  background: #ccc;
  cursor: pointer;
}

/* End Claim Survey Listing Section */

/* Start PDF Claim Survey List Section */
.PDFlistContainer {
  min-height: 80px;
  padding: 15px 0px 5px 2%;
  margin: 0px 0px 15px 0px;
  border: 1px solid #cdd6d6;
  border-radius: 10px;
}
/* End PDF Claim List Section */
/* End Home Page */

/* Start Survey Details Page Section */
.surveyDetails_mainContainer {
  margin: 110px 0px 0px 0px;
  background-color: #ecf2f2;
  padding: 0px 0px 50px 0px;
}
/* Start Survey No Section */
.surveyDetails_firstContainer {
  height: 85px;
  width: 100%;
  padding: 10px 30px 0px 4%;
  border-bottom: 1px solid #d3d4dc;
  margin: 0px 0px 0px 0px;
  background-color: #fff;
  position: fixed;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.claimSurveyNo_text {
  font-size: 24px;
  color: #363636;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}
.claimSurveyNo_text span {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}
.printStatus_container {
  text-align: right;
}
.surveyDetails_statusContainer {
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 2px 0px 0px 0px;
  border: 1px solid #2149e9;
  display: inline-flex;
  flex-direction: row;
}
.surveyDetails_statusText {
  width: 80%;
  border: 0px solid #000;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: #393939;
}
.surveyDetails_buttomArrowContainer {
  width: 20%;
  height: 100%;
  text-align: center;
  padding: 0px 0px 0px 1px;
  border: 0px solid #000;
}
/* .pageCount_textContainer { background-color: #7F8C8D; height: 30px; width: 30px; padding: 4px 0px 0px 0px; }
    .pageCount_text { color: #ffff;  text-align: center;  font-size: 18px;} */
/* .pagination_text {color: #7F8C8D; font-size: 20px;} */
/* End Survey No Section */

/* Start Grid System Section */
.SurveyDetails_secondContainer {
  padding: 90px 0px 0px 0px;
}
.SurveyDetails_HeaderContainer {
  margin: 40px 2% 10px 4%;
  border-bottom: 0px solid #bbb1ac74;
}
.SurveyDetails_HeaderContainer h4 {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}
.SurveyDetailsGrid_listContainer {
  min-height: 80px;
  padding: 20px 0px 5px 2%;
  margin: 20px 0px 15px 0px;
  border: 0px solid #cdd6d6;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.SurveyDetailsGrid_list_columnContainer {
  margin: 0px 0px 10px 0px;
}
.SurveyDetailsGrid_list_columnContainer h5 {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 12px;
  color: #393939;
}
.SurveyDetailsGrid_list_columnContainer h6 {
  font-size: 14px;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #393939;
}
.SurveyDetailsGrid_list_columnContainer img {
  height: 87%;
  width: 30%;
  border-radius: 5px;
  cursor: pointer;
}
.fullScreenButton {
  padding: 0px 10px 2px 10px;
  margin: 0px 0px 5px 10px;
  background-color: #7f8c8d;
  color: #ffff;
  border-radius: 5px;
  font-size: 12px;
}
.SurveyDetailsGrid_list_multipleImageContainer img {
  height: 70%;
  border-radius: 5px;
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}
/* End Grid System Section */

/* Start Comment Section */
.SurveyDetailsComment_HeaderContainer {
  margin: 40px 2% 50px 5%;
  border-bottom: 0px solid #bbb1ac74;
}
.SurveyDetailsComment_HeaderContainer textarea {
  width: 98%;
  height: 200px;
  margin: 20px 1% 20px 1%;
  padding: 5px 20px 5px 20px;
  background-color: #d8dee2;
  border-radius: 5px;
  border: 0px solid #7f8c8d;
}
.SurveyDetailsCancleButton {
  width: 200px;
  height: 35px;
  margin: 0px 20px 10px 0px;
  border-radius: 5px;
  background-color: #ffff;
  border: 1px solid #7f8c8d;
}
.SurveyDetailsSubmitButton {
  width: 200px;
  height: 35px;
  border-radius: 5px;
  border: 0px solid #7f8c8d;
  background-color: #111313;
  color: #ffff;
}
/* End Comment Section */

/* End  Survey Details Page Section */

/* Start Admin File Upload Section  */
.UploadFileAdmin_mainContainer {
  margin: 110px 0px 0px 0px;
  background-color: #f7f9fe;
}
.UploadFileAdmin_bodyContainer {
  padding: 50px 5% 0px 5%;
}
.uploadIntimated_headerText {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
}
.fileUpload_container {
  margin: 20px 0px 0px 0px;
  background-color: #ffffff;
  min-height: 300px;
  padding: 0px 8% 0px 8%;
  border-radius: 10px;
}
.fileUpload_container h5 {
  color: #bfb8b8;
  padding: 30px 0px 10px 0px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
}
.fileUploadArea_container {
  border: 2px solid #2149e9;
  padding: 25px 0px 25px 0px;
  width: 100%;
  background-color: #f4f8f8;
  border-radius: 10px;
  border-style: dashed;
}
.fileUploadArea_dragArea {
  border: 0px solid #000;
}

.DownloadIcon {
  margin: 0px 10px 0px 3%;
}
.dragDropInput {
  background-color: transparent;
  border: 0px solid #000;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: #000;
  width: 87%;
  margin: 2px 0px 0px 0px;
}
.fileUploadArea_BrowserArea {
  border: 0px solid #000;
  text-align: left;
}
.fileUploadBrowseFileInput_container {
  width: 100%;
  height: 30px;
  background-color: #2149e9;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 13px;
  padding: 5px 0px 0px 0px;
}
.fileUploadFile_supportFile {
  color: #bfb8b8;
  padding: 0px 0px 0px 0px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 12px;
}
.fileUploadFile_fileComment {
  font-weight: 700;
  min-height: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
  color: rgb(219, 75, 75);
}
.fileUploadFileButton_container {
  text-align: center;
  margin: 40px 0px 0px 0px;
}
.fileUploadFileButton {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 0px solid #000;
  margin: 0px 0px 20px 0px;
  color: #ffffff;
}
/* End Admin File Upload Section  */

/*  06.09.2021 rjn */

.rowsurveyFormFilter_mainItemCheckBox.darow span {
  padding-left: 0px;
  color: #000;
}
.date_pick {
  /* width: 92%; */
  width: inherit;
  border-bottom: 1px solid #ececec;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  padding: 2px 0px 5px 20px;
}
/* .rowsurveyFormFilter_mainItemCheckBox.darow:hover .date_pick { display: flex; } */

.dpiker {
  /* width: 40%; */
  background: #fff;
}
.dpiker span {
  font-size: 10px;
  padding-left: 0px;
}
.dpiker input {
  width: 100%;
}
.darow {
  position: relative;
}
.darow:after {
  content: "";
  border: solid gray;
  border-width: 0px 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 10px;
}
.aoblock {
  display: block;
  width: 90%;
  position: absolute;
  top: 27px;
  right: -269px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  max-height: 120px;
  overflow-y: scroll;
}
.aosubblock {
  display: block;
}
.aosubblock span {
  color: #878787;
  font-size: 10px;
}
/* .rowsurveyFormFilter_mainItemCheckBox.darow:hover .aoblock { display: block; } */
.surveyFormFilter_download {
  position: fixed;
  top: 200px;
  left: 36%;
  width: 150px;
  border: 1px solid rgb(230, 224, 224);
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #7f8c8d;
  padding: 8px 15px 8px 15px;
}

.surveyFormFilter_download p {
  margin-bottom: 0px;
  text-align: center;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 18px;
}

.surveyFormFilter_print {
  position: fixed;
  top: 200px;
  left: 28%;
  width: 150px;
  border: 1px solid rgb(230, 224, 224);
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #7f8c8d;
  padding: 8px 0px 8px 0px;
}

.surveyFormFilter_print p {
  margin-bottom: 0px;
  text-align: center;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 24px;
  font-size: 15px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.surveyFormFilter_print p:last-child {
  border-bottom: 0px;
}

/* modal css */
.modalmn .modal-content {
  border-radius: 15px;
}
.modal-dialog {
  max-width: 600px;
}
.modalmn .modal-title.h4 {
  text-align: center;
  color: #000;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  width: 100%;
}

.modalbdy {
  width: 100%;
  text-align: center;
}
.modalbdy.modal-body h4 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #9d6d6d;
}

.modalbdy.modal-body h4 span {
  color: #7f7f7f;
}
.modalPicText_row h5 {
  color: #414141;
  font-size: 18px;
}
.modalPicText_row {
  display: inline;
  flex-direction: row;
  border: 0px solid #000;
}
.closeIconContainer {
  text-align: right;
  margin-top: 10px;
}
.closeIconContainer span {
  margin: 0px 10px 0px 0px;
  padding: 0px 6px 3px 6px;
  background-color: #c0b6b6;
  cursor: pointer;
  border-radius: 30px;
}

.modalmn .modal-header {
  border: 0px;
}
.modalbtn {
  background: #2953b7;
  color: #fff;
}
.modalbdr.modal-footer {
  border: 0px;
  text-align: center;
  width: 100%;
  display: block;
}
button.modalbtnC.btn.btn-secondary {
  background: #fff;
  border: 1px solid #2953b7;
  width: 210px;
  height: 50px;
  color: #2953b7;
}
button.modalbtnD.btn.btn-primary {
  background: #2953b7;
  border: 1px solid #2953b7;
  width: 210px;
  height: 50px;
  color: #fff;
}

.headerInput:focus {
  border: 0px;
  outline: 0px;
}

.other_docs_row img {
  width: 9%;
}

.video-thumbnail {
  height: 130px;
  border-radius: 5px;
}

/*-----------------------------------------------------------------------*/
/*--------------------------- Responsive --------------------------------*/
/*-----------------------------------------------------------------------*/

@media (max-width: 1199px) {
  .video-thumbnail {
    height: 98px;
  }
  .profileIcon {
    width: 35px;
    height: 35px;
  }
  .profileName {
    font-size: 14px;
  }

  .pageCount_text {
    font-size: 15px;
  }
  .pagination_text {
    font-size: 15px;
  }

  /* Survey Details Page */
  .claimSurveyNo_text {
    font-size: 22px;
  }
}

@media (max-width: 1064px) {
  .video-thumbnail {
    height: 80px;
  }
  .headerContainer {
    padding: 30px 0px 0px 4%;
    height: 120px;
  }
  .profileIcon {
    width: 35px;
    height: 35px;
  }
  .profileName {
    font-size: 14px;
  }
  /* Home Page  */
  .surveyFormList_mainContainer {
    margin: 120px 0px 0px 0px;
  }
  /* Survey Details Page */
  .surveyDetails_mainContainer {
    margin: 120px 0px 0px 0px;
  }
  .claimSurveyNo_text {
    font-size: 20px;
  }
  .claimSurvey_visibleClaimCount {
    font-size: 12px;
    width: 100%;
  }
  .pagination_text {
    font-size: 12px;
  }
  /* Admin File Upload  */
  .UploadFileAdmin_mainContainer {
    margin: 120px 0px 0px 0px;
  }
}

@media (max-width: 991px) {
  .surveyFormFilter_download {
    top: 230px;
  }
  .surveyFormFilter_print {
    top: 230px;
  }

  .headerContainer {
    padding: 30px 0px 0px 4%;
    height: 150px;
  }
  .headerLogo {
    margin: 0px 0px 15px 5px;
  }
  .Profile_container {
    padding: 2px 0px 0px 0px;
  }
  .profileIcon {
    width: 35px;
    height: 35px;
    margin: 5px 0px 0px 0px;
  }
  .profileName {
    font-size: 12px;
  }
  .searchListSurveyContainer {
    width: 46%;
    left: 4%;
    top: 150px;
  }
  .signinView_container {
    top: 140px;
    right: 70px;
    height: 100px;
    width: 200px;
  }
  /* Home Page  */
  .surveyFormList_mainContainer {
    margin: 150px 0px 0px 0px;
  }
  .paginationContainer {
    min-height: 90px;
    padding: 40px 0px 0px 4%;
    margin: 0px 0px 40px 0px;
  }
  .leftPagination_container {
    margin: 0px 0px 10px 0px;
  }
  .claimSurvey_text {
    /* font-size: 20px; */
    text-align: left;
  }
  .rightPagination_container {
    text-align: left;
  }
  .pageCount_dropdown {
    text-align: left;
  }
  .pageCount_textContainer {
    width: 25px;
    height: 12;
    padding: 7px 0px 0px 0px;
  }
  .pageCount_text {
    font-size: 13px;
  }
  .pagination_text {
    font-size: 15px;
    padding: 0px 0px 0px 0px;
  }
  .SurveyFormList_tableHeader {
    font-size: 14px;
  }
  .SurveyFormList_tableRowBody td {
    font-size: 13px;
  }
  /* Home Filter Section  */
  .surveyFormFilter_container {
    top: 240px;
    left: 40%;
  }
  /* .Listing_mainContainer {margin: 20px 30px 0px 30px; } */

  /* Survey Details Page */
  .surveyDetails_mainContainer {
    margin: 150px 0px 0px 0px;
  }

  .printStatus_container {
    text-align: left;
    margin: 20px 0px 0px 0px;
  }
  /* Admin File Upload  */
  .UploadFileAdmin_mainContainer {
    margin: 150px 0px 0px 0px;
  }
}

@media (max-width: 831px) {
  .pageCount_text {
    font-size: 15px;
  }
  .pagination_text {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .video-thumbnail {
    height: 100px;
  }

  .surveyFormFilter_container {
    left: 10%;
  }
  .surveyFormFilter_download {
    left: 75%;
  }
  .surveyFormFilter_print {
    left: 60%;
  }

  .headerContainer {
    height: 150px;
    padding: 30px 15px 0px 4%;
  }
  .profileIcon {
    width: 30px;
    height: 30px;
  }
  .profileName {
    font-size: 13px;
  }
  .searchListSurveyContainer {
    width: 46%;
    left: 2%;
    top: 150px;
  }
  .signinView_container {
    top: 140px;
    right: 40px;
  }
  /* Home Page  */
  .surveyFormList_mainContainer {
    margin: 150px 0px 0px 0px;
  }
  .paginationContainer {
    min-height: 90px;
    padding: 40px 0px 0px 4%;
    margin: 0px 0px 30px 0px;
  }
  /* Home Intimated Section  */
  .IntimatedClaim_active {
    font-size: 16px;
  }
  .IntimatedClaim_nonActive {
    font-size: 15px;
  }
  /* .Listing_mainContainer {margin: 20px 1% 0px 1%; } */
  .SurveyFormList_tableHeader {
    font-size: 14px;
  }
  .SurveyFormList_tableRowBody td {
    font-size: 13px;
  }
  .headTable th {
    padding: 20px 8px;
    font-weight: 500;
    font-size: 15px;
  }
  .headTable td {
    font-weight: 400;
    font-size: 15px;
  }
  /* Survey Details Page */
  .surveyDetails_mainContainer {
    margin: 150px 0px 0px 0px;
  }
  /* Admin Upload File Page Section  */
  .fileUploadArea_BrowserArea {
    padding: 10px 0px 0px 0px;
  }

  .other_docs_row img {
    width: 15%;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 568px) {
  .surveyFormFilter_download {
    left: 67%;
  }
  .surveyFormFilter_print {
    left: 53%;
  }
  .headerContainer {
    height: 140px;
    padding: 30px 10px 0px 4%;
  }
  .headerInput_container {
    padding: 0px 0px 0px 5px;
  }
  .headerInput {
    padding: 0px 10px 0px 5px;
    height: 40px;
    font-size: 13px;
  }
  .searchIcon {
    padding: 6px 1.5% 0px 0px;
  }
  .arrowBottom {
    padding: 5px 0px 0px 0px;
  }
  .profileIcon {
    width: 25px;
    height: 25px;
  }
  .profileName {
    padding: 7px 0px 0px 0px;
    font-size: 12px;
  }
  /* Home Page  */
  .surveyFormList_mainContainer {
    margin: 140px 0px 0px 0px;
  }
  /* Survey Details Page */
  .surveyDetails_mainContainer {
    margin: 140px 0px 0px 0px;
  }
  .surveyDetails_firstContainer {
    padding: 20px 0px 0px 4%;
    border-bottom: 1px solid #d3d4dc;
    margin: 0px 0px 0px 0px;
  }
  .printStatus_container {
    text-align: left;
    margin: 20px 0px 0px 0px;
  }
  .claimSurveyNo_text,
  .claimSurveyNo_text span {
    font-size: 16px;
  }
  .surveyFormFilter_container {
    top: 240px;
    left: 15%;
  }
  /* Admin File Upload  */
  .UploadFileAdmin_mainContainer {
    margin: 140px 0px 0px 0px;
  }

  .other_docs_row img {
    width: 20%;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 480px) {
  .surveyFormFilter_download {
    left: 65%;
  }
  .surveyFormFilter_print {
    left: 56%;
  }

  /* Home Page  */
  .surveyFormFilter_container {
    top: 220px;
    left: 15%;
    width: 280px;
  }
  /* Survey Details Page */
  .claimSurveyNo_text,
  .claimSurveyNo_text span {
    font-size: 14px;
  }
  .surveyDetails_statusText {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .profileIcon {
    width: 25px;
    height: 25px;
  }
  .profileName {
    /* font-size: 10px; */
    padding: 9px 0px 0px 0px;
  }
  .signinView_container {
    top: 140px;
    right: 30px;
  }

  .pagination_text {
    font-size: 13px;
  }
  /* Home Intimated Section  */
  .IntimatedClaim_active {
    font-size: 15px;
  }
  .IntimatedClaim_nonActive {
    font-size: 14px;
  }
  .SurveyFormList_tableHeader {
    font-size: 14px;
  }
  .SurveyFormList_tableRowBody td {
    font-size: 13px;
  }
}

svg {
  vertical-align: unset !important;
}
